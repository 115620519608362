<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车辆维修</el-breadcrumb-item>
      <el-breadcrumb-item>维修信息</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <div class="top">
        <div>
          <el-button type="primary" @click="opentan()">添加信息</el-button>
        </div>

        <div class="tops">
          <span>搜索车牌号：</span>
          <el-autocomplete
            :trigger-on-focus="false"
            placeholder="请输入车牌号"
            v-model="key"
            :fetch-suggestions="salespersonsou"
            value-key="auto_number"
            @select="XiaohandleSelectsou($event, '车牌号')"
          ></el-autocomplete>
        </div>

        <div class="tops">
          <span>使用单位：</span>
          <el-select v-model="current_org" clearable>
            <el-option v-for="item in trashtypeData" :key="item.id" :label="item.node_name" :value="item.id" @click.native="choicedrug"></el-option>
          </el-select>
        </div>

        <div class="tops riqi">
          <span>日期：</span>
          <!-- <el-date-picker v-model="month" type="month" @change="gettime" value-format="yyyy-MM" placeholder="选择月"></el-date-picker> -->
          <el-date-picker
            @change="gettime"
            v-model="timeFrom"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <!-- 搜索车牌号 -->
        </div>

        <div class="tops">
          <el-button type="primary" @click="chongxin()">重新选择</el-button>
        </div>
      </div>

      <div>
        <el-button class="boder">维修花费 {{ toFixed(month_total_money) }} 元</el-button>
        <!-- <el-button class="boder">本年度维修花费{{ year_total_money }} 元</el-button> -->
      </div>

      <!-- 添加弹窗 -->
      <el-dialog title="新增维修信息" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
        <el-form ref="dform" :model="dform" label-width="100px" :rules="rules">
          <el-form-item label="车辆" label-width="150px">
            <el-autocomplete
              :trigger-on-focus="false"
              placeholder="请输入车牌"
              v-model="dform.auto_number"
              :fetch-suggestions="salespersonss"
              value-key="auto_number"
              @select="XiaohandleSelectss($event, '车牌号')"
            ></el-autocomplete>
          </el-form-item>

          <el-form-item label="维修日期" label-width="150px" prop="maintenance_time_at">
            <el-date-picker value-format="yyyy-MM-dd" v-model="dform.maintenance_time_at" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>

          <el-form-item label="司机名称" label-width="150px" prop="auto_driver">
            <div class="inp"><el-input type="text" v-model="dform.auto_driver" placeholder="司机名称"></el-input></div>
          </el-form-item>

          <!-- <el-form-item label="车辆类型" prop="property" label-width="150px">
            <el-cascader ref="tree" @change="optpidfun" v-model="dform.aa" :options="usetypelist" clearable :props="casprops"></el-cascader>
          </el-form-item> -->

          <el-form-item label="维修公里数" label-width="150px" prop="kilometre">
            <div class="inp"><el-input v-model="dform.kilometre" placeholder="公里数"></el-input></div>
          </el-form-item>

          <el-form-item label="结算方式" label-width="150px" prop="sett_method">
            <el-select v-model="dform.sett_method" placeholder="未选择" clearable>
              <el-option v-for="item in typelist" :key="item.id" :label="item.name" :value="item.id" @click.native="chetype"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="维修单位" label-width="150px" prop="repair_man">
            <el-select v-model="dform.repair_man" placeholder="未选择" clearable>
              <el-option v-for="item in tableDatas" :key="item.id" :label="item.maintenance_company" :value="item.id" @click.native="chetype"></el-option>
            </el-select>
          </el-form-item>

          <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
              <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAddZJ">新增</el-button>
            </el-col>
            <el-col :span="1.5">
              <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="handleDeleteZJ">删除</el-button>
            </el-col>
            <!-- <el-col :span="1.5">
              <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="aaaaa">清空</el-button>
            </el-col> -->
            <el-col :span="1.5">
              <span>单价，数量，工时费，均为数字</span>
            </el-col>
          </el-row>

          <el-table :data="dform.info_list" ref="zztable" @selection-change="handleSelectionChangezz" :row-class-name="zzTableRowClassName">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="序号" type="index" width="50" />
            <el-table-column prop="part_name" label="项目">
              <template slot-scope="scope">
                <el-input v-model="scope.row.part_name"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="part_price" label="单价">
              <template slot-scope="scope">
                <el-input v-model="scope.row.part_price"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="part_num" label="数量">
              <template slot-scope="scope">
                <el-input v-model="scope.row.part_num"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="hourly_wage" label="工时费">
              <template slot-scope="scope">
                <el-input v-model="scope.row.hourly_wage"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="heji" label="合计" align="center">
              <template slot-scope="scope">
                {{ Number(scope.row.part_price) * Number(scope.row.part_num) + Number(scope.row.hourly_wage) + '元' }}
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelfun()">取 消</el-button>
          <el-button type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确定</el-button>
        </span>
      </el-dialog>

      <!-- 编辑弹窗 -->
      <el-dialog title="新增维修信息" :visible.sync="enddialogVisible" width="40%" :before-close="endhandleClose">
        <el-form ref="rform" :model="rform" label-width="100px" :rules="ruless">
          <el-form-item label="车辆" label-width="150px">
            <el-autocomplete
              :trigger-on-focus="false"
              placeholder="请输入车牌"
              v-model="rform.auto_number"
              :fetch-suggestions="salespersonss"
              value-key="auto_number"
              @select="XiaohandleSelectss($event, '车牌号')"
            ></el-autocomplete>
          </el-form-item>

          <el-form-item label="维修日期" label-width="150px" prop="maintenance_time_at">
            <el-date-picker value-format="yyyy-MM-dd" v-model="rform.maintenance_time_at" type="date" placeholder="选择日期"></el-date-picker>
          </el-form-item>

          <el-form-item label="司机名称" label-width="150px" prop="auto_driver">
            <div class="inp"><el-input type="text" v-model="rform.auto_driver" placeholder="司机名称"></el-input></div>
          </el-form-item>

          <!-- <el-form-item label="车辆类型" prop="property" label-width="150px">
            <el-cascader ref="tree" @change="optpidfun" v-model="rform.aa" :options="usetypelist" clearable :props="casprops"></el-cascader>
          </el-form-item> -->

          <el-form-item label="维修公里数" label-width="150px" prop="kilometre">
            <div class="inp"><el-input v-model="rform.kilometre" placeholder="公里数"></el-input></div>
          </el-form-item>
          <!-- onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" -->
          <el-form-item label="结算方式" label-width="150px" prop="sett_method">
            <el-select v-model="rform.sett_method" placeholder="未选择" clearable>
              <el-option v-for="item in typelist" :key="item.id" :label="item.name" :value="item.id" @click.native="chetype"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="维修单位" label-width="150px" prop="repair_man">
            <el-select v-model="rform.repair_man" placeholder="未选择" clearable>
              <el-option v-for="item in tableDatas" :key="item.id" :label="item.maintenance_company" :value="item.id" @click.native="chetype"></el-option>
            </el-select>
          </el-form-item>
          <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
              <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="endhandleAddZJ">新增</el-button>
            </el-col>
            <el-col :span="1.5">
              <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="endhandleDeleteZJ">删除</el-button>
            </el-col>
          </el-row>
          <el-table :data="rform.info_list" ref="zztable" @selection-change="handleSelectionChangezz" :row-class-name="zzTableRowClassName">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="序号" type="index" width="50" />
            <el-table-column prop="part_name" label="项目">
              <template slot-scope="scope">
                <el-input v-model="scope.row.part_name"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="part_price" label="单价">
              <template slot-scope="scope">
                <el-input v-model="scope.row.part_price" type="number"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="part_num" label="数量" type="number">
              <template slot-scope="scope">
                <el-input v-model="scope.row.part_num"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="hourly_wage" label="工时费" type="number">
              <template slot-scope="scope">
                <el-input v-model="scope.row.hourly_wage"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="heji" label="合计" align="center">
              <template slot-scope="scope">
                {{ Number(scope.row.part_price) * Number(scope.row.part_num) + Number(scope.row.hourly_wage) + '元' }}
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="endcancelfun()">取 消</el-button>
          <el-button type="primary" @keyup.enter.native="addgoto()" @click="endaddgoto(tantype)">确定</el-button>
        </span>
      </el-dialog>

      <!--    列表开始-->
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="maintenance_time_at" label="维修日期"></el-table-column>

        <el-table-column prop="auto_number" label="车牌号"></el-table-column>

        <el-table-column label="车辆类型" width="180">
          <template slot-scope="scope">
            <div id="test">{{ scope.row.get_use_type ? scope.row.get_use_type.tname : '管理车辆' }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="kilometre" label="维修公里数(KM)">
          <template slot-scope="scope">
            <div id="test">{{ toFixed(scope.row.kilometre) }}</div>
          </template>
        </el-table-column>

        <el-table-column label="维修总计(元)" width="180">
          <template slot-scope="scope">
            <div id="test">{{ toFixed(scope.row.money / 100) }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="auto_driver" label="司机"></el-table-column>

        <!-- <el-table-column label="维修单位" width="180">
          <template slot-scope="scope">
            <div id="test">{{ scope.row.get_maintenance_company ? scope.row.get_maintenance_company.maintenance_company : '无' }}</div>
          </template>
        </el-table-column> -->

        <el-table-column prop="maintenance_company" label="维修单位" width="180"></el-table-column>

        <el-table-column prop="sett_method_cn" label="结算方式"></el-table-column>

        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editfun(scope.row.id)"></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click="delfun(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { GetautoList, GetUseTypeList, Getlist, Setwei, Getweilist, Endwei, Getdantiao, Shandan, Getxiangmu } from '../../../api/car'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      start_time: '',
      end_time: '',
      timeFrom: [],
      trashtypeData: [], // 项目下拉框的数据
      current_org: '', // 使用单位
      che_id: '', // 列表搜索车辆id
      key: '', // 搜索
      month: '', // 月度
      rules: {
        maintenance_time_at: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        kilometre: [{ required: true, message: '公里数为数字', trigger: 'blur' }],
        sett_method: [{ required: true, message: '请选择结算方式', trigger: 'change' }],
        repair_man: [{ required: true, message: '请选择维修厂', trigger: 'change' }],
        auto_driver: [
          { required: true, message: '请输入司机姓名', trigger: 'blur' },
          { min: 1, message: '请输入司机姓名', trigger: 'blur' }
        ]
      },
      ruless: {
        maintenance_time_at: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        kilometre: { required: true, message: '请填写公里数为数字', trigger: 'blur' },
        sett_method: [{ required: true, message: '请选择结算方式', trigger: 'change' }],
        repair_man: [{ required: true, message: '请选择维修厂', trigger: 'change' }],
        auto_driver: [
          { required: true, message: '请输入司机姓名', trigger: 'blur' },
          { min: 1, message: '请输入司机姓名', trigger: 'blur' }
        ]
      },

      month_total_money: 0, // 月度维修总费用
      year_total_money: '', // 年度维修总费用
      tantype: 'add', // 状态
      dialogVisible: false, // 对话框
      enddialogVisible: false, // 对话框
      // auto_number: '',
      tableDatas: [],
      tableData: [],
      idszz: [],
      dform: {
        auto_number: '',
        maintenance_time_at: '', // 维修时间
        auto_id: '', // 车辆id
        auto_driver: '', // 司机
        kilometre: '', // 维修公里数
        sett_method: '', // 结算方式
        from_source: 'mobile',
        repair_man: '',
        info_list: []
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },

      rform: {
        id: '',
        auto_number: '',
        maintenance_time_at: '', // 维修时间
        auto_id: '', // 车辆id
        auto_driver: '', // 司机
        kilometre: '', // 维修公里数
        sett_method: '', // 结算方式
        from_source: 'mobile',
        repair_man: '',
        info_list: []
      },

      usetypelist: [], // 使用类型列表
      casprops: { value: 'id', label: 'tname', checkStrictly: false }, // 级联选择的配置

      // 结账时间
      typelist: [
        { name: '现结', id: 1 },
        { name: '月结', id: 2 }
      ]
    }
  },
  mounted() {
    this.Getweilistya()
    this.Getxiangmulist()
  },
  methods: {
    toFixed(val) {
      return parseFloat(val).toLocaleString('en', {
        // minimumFractionDigits: 2,
        // maximumFractionDigits: 2,
      })
    },
    toFixeds(val) {
      return parseFloat(val).toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    // 项目下拉框选中的触发事件
    choicedrug() {
      this.Getweilistya()
    },

    // 输入车辆匹配数据
    salespersonsou(queryString, cb) {
      this.getallfun(queryString, cb)
    },

    // 搜索车辆选中
    XiaohandleSelectsou(e, term) {
      this.che_id = e.id
      console.log(this.che_id)
      this.Getweilistya()
    },

    // 时间
    gettime(month) {
      console.log(month)
      this.start_time = month[0]
      console.log(this.start_time)
      this.end_time = month[1]
      console.log(this.end_time)
      this.Getweilistya()
    },

    // 编辑
    editfun(e) {
      // console.log(e)
      this.rform.id = e
      this.Getdantiaos(e)
      this.enddialogVisible = true
      this.Getlists()
    },

    // 确定添加
    addgoto(type) {
      console.log(type)
      this.$refs['dform'].validate(v => {
        if (v) {
          this.ClientInformations(this.dform)
          this.$refs.dform.resetFields()
          this.dform.auto_number = ''
          this.dform.info_list = []
          this.dform.auto_id = ''
          this.dform.kilometre = ''
        }
      })
    },

    endaddgoto(type) {
      this.$refs['rform'].validate(v => {
        if (v) {
          this.editcompanyfun(this.rform)
        }
      })
    },

    // 取消
    cancelfun() {
      // this.dform = {}
      this.handleClose()
      this.$refs.dform.resetFields()
      this.dform.info_list = []
    },

    // 删除
    delfun(id) {
      // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Endlists(id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    endcancelfun() {
      this.enddialogVisible = false
    },

    // 关闭添加弹窗
    handleClose() {
      this.dialogVisible = false
      // this.aaaaa()
    },

    endhandleClose() {
      this.enddialogVisible = false
    },

    // 打开弹窗
    opentan() {
      this.dialogVisible = true
      this.getusetypelistfun()
      this.Getlists()
    },

    salespersonss(queryString, cb) {
      this.getallfun(queryString, cb)
    },

    // 搜索车辆选中
    XiaohandleSelectss(e, term) {
      console.log(e.id)
      // console.log(e);
      this.dform.auto_id = e.id
      this.rform.auto_id = e.id
    },

    // 选择级联中的值
    optpidfun(e) {
      console.log(e)
      this.dform.aa = e[2] ? e[2] : 0
    },
    chetype(e) {},

    //添加行号
    zzTableRowClassName({ row, rowIndex }) {
      row.xh = rowIndex + 1
    },

    // 多选框选中数据
    handleSelectionChangezz(selection) {
      this.idszz = selection.map(item => item.fid)
    },

    //新增专家行
    handleAddZJ() {
      let obj = {}
      this.dform.info_list.push(obj)
    },

    //删除专家行
    handleDeleteZJ() {
      let arr = this.$refs.zztable.selection
      const arr1 = []
      for (let i = 0; i < arr.length; i++) {
        arr1.push(arr[i].xh)
      }
      //数字数组排序，因为选择框是根据先选择的顺序存到checkedDetail里面，所以需要排序
      arr1.sort(function (a, b) {
        return a - b
      })
      for (let i = 0; i < arr1.length; i++) {
        this.dform.info_list.splice(arr1[i] - i - 1, 1)
      }
    },

    chongxin() {
      this.key = ''
      this.che_id = ''
      this.month = ''
      this.current_org = ''
      this.Getweilistya()
    },

    //新增专家行
    endhandleAddZJ() {
      let obj = {}
      this.rform.info_list.push(obj)
    },

    //删除专家行
    endhandleDeleteZJ() {
      let arr = this.$refs.zztable.selection
      const arr1 = []
      for (let i = 0; i < arr.length; i++) {
        arr1.push(arr[i].xh)
      }
      //数字数组排序，因为选择框是根据先选择的顺序存到checkedDetail里面，所以需要排序
      arr1.sort(function (a, b) {
        return a - b
      })
      for (let i = 0; i < arr1.length; i++) {
        this.rform.info_list.splice(arr1[i] - i - 1, 1)
      }
    },

    aaaaa() {
      // console.log(111)
      this.rform.info_list = []
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getweilistya()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Getweilistya()
    },

    //////////////请求区///////////////////

    // 添加维修信息
    async ClientInformations(v) {
      const { data } = await Setwei(v)
      this.getanpostfun(data, '添加', this.Getweilistya())
    },

    // 获取车辆
    async getallfun(keys, cd) {
      const { data } = await GetautoList({ key: keys, page: 1, size: 100 })
      this.userlist = data.data.list
      cd(data.data.list)
    },

    // 获取使用类型
    async getusetypelistfun() {
      const { data } = await GetUseTypeList({ page: 1, size: 10000 })
      // console.log(data);
      this.usetypelist = data.data.list
      this.toplist = data.data.list[0].children
    },

    // 拉取维修单位列表
    async Getlists() {
      const { data } = await Getlist({ status: 1 })
      // console.log(data)
      this.tableDatas = data.data.list
    },

    // 拉取维修信息列表
    async Getweilistya() {
      const { data } = await Getweilist({ ...this.pageData, start_time: this.start_time, end_time: this.end_time, auto_id: this.che_id, current_org: this.current_org })
      // console.log(data)
      this.tableData = data.data.list
      this.year_total_money = data.data.year_total_money / 100
      this.month_total_money = data.data.month_total_money / 100
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 修改维修单条信息
    async editcompanyfun(v) {
      const { data } = await Endwei(v)
      this.getanpostfun(data, '修改', this.Getweilistya(), (this.enddialogVisible = false))
    },

    // 获取维修信息单条
    async Getdantiaos(id) {
      const { data } = await Getdantiao({ id })
      this.rform.auto_number = data.data.auto_number
      this.rform.maintenance_time_at = data.data.maintenance_time_at // 维修时间
      this.rform.auto_id = data.data.auto_id // 车辆id
      this.rform.auto_driver = data.data.auto_driver // 司机
      this.rform.kilometre = data.data.kilometre // 维修公里数
      this.rform.sett_method = data.data.sett_method // 结算方式
      this.rform.from_source = 'mobile'
      this.rform.repair_man = data.data.repair_man
      this.rform.info_list = data.data.get_auto_maintenance_parts
    },

    // 删除单条数据
    async Endlists(id) {
      const { data } = await Shandan({ id })
      this.getanpostfun(data, '删除', this.Getweilistya())
    },

    // 获取项目
    async Getxiangmulist() {
      const { data } = await Getxiangmu()
      // console.log(data)
      this.trashtypeData = data.data
      // console.log(this.trashtypeData)
    },

    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`, this.handleClose())
      }
    }
  }
}
</script>

<style scoped>
.inp {
  width: 220px;
}
.top {
  width: 1400px;
  display: flex;
}
.tops {
  margin-left: 15px;
}
</style>